import { Inject, Injectable } from '@angular/core';
import { GraphQlClientLoader, GRAPHQL_CLIENT_LOADER, parseGraphQlErrors } from '@origin8-web/core-ui/graphql';
import {
  AdvocateRecommendedTaskClick,
  O8RootQueryFilter,
  OpenLead,
  Pod,
  PrioritizedLeadRecord,
  RecommendedTask,
} from 'common.interfaces';
import { catchError, map, Observable } from 'rxjs';
import { GetOpenLeadsQuery, GetPodsQuery, GetPrioritizedLeadsQuery, GetRecommendedTasksQuery } from './gql/tasks.query';
import { MutateNewAdvocateRecommendedTaskClickEvent } from './gql/tasks.mutations';

@Injectable({
  providedIn: 'root',
})
export class TasksApi {
  constructor(@Inject(GRAPHQL_CLIENT_LOADER) private graphqlClientLoader: GraphQlClientLoader) {}

  getPods(filters: O8RootQueryFilter<Pod>): Observable<Pod[]> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ podResults: { results: Pod[] } }>({
        query: GetPodsQuery,
        variables: {
          filters,
        },
      })
      .pipe(
        map((r) => r.data.podResults.results),
        catchError(parseGraphQlErrors),
      );
  }

  getPrioritizedLeads(email: string): Observable<PrioritizedLeadRecord[]> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ prioritizedLeads: { records: PrioritizedLeadRecord[] } }>({
        query: GetPrioritizedLeadsQuery,
        variables: {
          email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((r) => r.data.prioritizedLeads.records),
        catchError(parseGraphQlErrors),
      );
  }

  getRecommendedTasks(email: string): Observable<RecommendedTask[]> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{ RecommendedTasks: RecommendedTask[] }>({
        query: GetRecommendedTasksQuery,
        variables: {
          email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data.RecommendedTasks;
        }),
        catchError(parseGraphQlErrors),
      );
  }

  getOpenLeads(filters: O8RootQueryFilter<OpenLead>): Observable<
    Array<
      OpenLead & {
        leadCreatedTime: Date;
        lastCallTime: Date;
        nonVarPolicySentDate: Date;
        kitSentDate: Date;
        policyEffectiveDate: Date;
        nextFollowUpDate: Date;
      }
    >
  > {
    return this.graphqlClientLoader
      .getSecuredClient()
      .query<{
        LeadsResults: {
          results: Array<
            OpenLead & {
              leadCreatedTime: Date;
              lastCallTime: Date;
              nonVarPolicySentDate: Date;
              kitSentDate: Date;
              policyEffectiveDate: Date;
              nextFollowUpDate: Date;
            }
          >;
        };
      }>({
        query: GetOpenLeadsQuery,
        variables: {
          filters,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => {
          return res.data.LeadsResults.results;
        }),
        catchError(parseGraphQlErrors),
      );
  }

  triggerAdvocateRecommendedTaskClickEvent(event: AdvocateRecommendedTaskClick): Observable<void> {
    return this.graphqlClientLoader
      .getSecuredClient()
      .mutate<void>({
        mutation: MutateNewAdvocateRecommendedTaskClickEvent,
        variables: {
          advocateRecommendedTaskClick: event,
          eventSource: 'Protec8',
        },
      })
      .pipe(map(() => undefined));
  }
}
