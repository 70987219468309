import { gql } from '@apollo/client';

export const MutateNewAdvocateRecommendedTaskClickEvent = gql`
  mutation NewAdvocateRecommendedTaskClickEvent(
    $advocateRecommendedTaskClick: AdvocateRecommendedTaskClick!
    $eventSource: String
  ) {
    newAdvocateRecommendedTaskClickEvent(
      advocateRecommendedTaskClick: $advocateRecommendedTaskClick
      eventSource: $eventSource
    )
  }
`;
