import { gql } from '@apollo/client';

export const GetPrioritizedLeadsQuery = gql`
  query GetPrioritizedLeads($email: String) {
    prioritizedLeads(email: $email) {
      records {
        recordType
        recordDetails {
          carrierFromAd
          name
          externalIdentifier
          stateFromAd
          createdOn
          productFromAd
        }
      }
    }
  }
`;

export const GetRecommendedTasksQuery = gql`
  query GetRecommendedTasks($email: String) {
    RecommendedTasks(email: $email) {
      externalResourceType
      externalSource
      externalIdentifier
      priority
      task
      hideByDefault
      numberOfCallAttempts
      leadInfo {
        externalIdentifier
        leadStatus
        advocateNote
        salesforceIdLeadOwner
        name
        leadCreatedTime
        lastName
        selfServe
        leadUuid
        carrierFromAd
        channelType
        leadSource
        isDeleted
        phone
        salesforceIdLastModifiedBy
        firstName
        email
        productFromAd
        stateFromAd
        benefitFromAd
        age
        firstCallAdvocate
        firstCallResult
        lastCallResult
        state
        pod
        firstCallTime
        lastCallTime
        lastCallAdvocate
        nonVarPolicySentDate
        kitSentDate
        currentPosition
        positionDetail
        policyEffectiveDate
        contactType
        dropDate
        rating
        isLeadAutoAssignment
        nextFollowUpDate
      }
    }
  }
`;

export const GetOpenLeadsQuery = gql`
  query GetOpenLeads($filters: O8FiltersInput) {
    LeadsResults(filters: $filters) {
      results {
        externalIdentifier
        salesforceIdLeadOwner
        leadStatus
        advocateNote
        name
        leadCreatedTime
        carrierFromAd
        channelType
        leadSource
        productFromAd
        stateFromAd
        lastCallResult
        lastCallTime
        nonVarPolicySentDate
        kitSentDate
        currentPosition
        positionDetail
        policyEffectiveDate
        rating
        pod
        nextFollowUpDate
      }
    }
  }
`;

export const GetPodsQuery = gql`
  query GetPods($filters: O8RootFiltersInput) {
    podResults(filters: $filters) {
      results {
        name
      }
    }
  }
`;
